html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Noto Sans Thai UI', 'Roboto', sans-serif;
}

body {
  background-color: white;
  overflow-x: hidden;
}

#root {
  background-color: white;
}

@media (min-width: 1024px) {
  .scroll-bg {
    -webkit-animation-name: scrollLarge;
    animation-name: scrollLarge;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}
html,
body {
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  height: 100%;
}

@keyframes scrollLarge {
  0% {
    transform: rotate(-13deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(-13deg) translate3d(0, -1113px, 0);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (hover: none) {
  .MuiPaginationItem-page.Mui-selected:hover,
  .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./assets/fonts/NotoSansThaiUI-Regular.woff2);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./assets/fonts/NotoSansThaiUI-Medium.woff2);
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./assets/fonts/NotoSansThaiUI-SemiBold.woff2);
  font-weight: 600;
  font-display: swap;
}
